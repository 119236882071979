.main {
    /* background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; */
  }

.header {
    text-align: center;
    margin: 20px;
    text-decoration: underline;
}

.events {
    padding: 20px;
}

.title {
    text-align: center;
    font-size: 22px;
}

.image {
  width: 100%;
  padding: 10px;
}

.divider {
    height: 1px; 
    width: 100%;
    margin: 20px;
    background-color: darkgray;
    border-radius: 6px;
}