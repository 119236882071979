.main {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-height: 100vh; */
  }

.header {
    text-align: center;
    margin: 50px;
    max-width: 500px;
}

.servicesCollection {
    display: flex;
    flex-direction: column;
    max-width: 800px;
   /*  flex-wrap: wrap;
    flex-direction: column;
    padding: 0 4px;
    align-items: center;
    justify-content: center; */
}

.service {
    display: flex;
    flex-direction: row;
    margin: 10px;
   /*  flex-wrap: wrap;
    flex-direction: row; */
}

.serviceSeparator {
    background-color: lightgray;
    max-width: 800px;
    height: 2px;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.serviceText {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 50%;
}

.serviceName {
    font-size: 24px;
}

.service img {
  /* margin-top: 8px;
  vertical-align: middle;
  width: 100%;
   */
    object-fit: cover;
    width: 300px;
    height: 300px;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .service {
        flex-direction: column;
        align-items: center;
    }

    .serviceText {
        text-align: left;
        display: flex;
        flex-direction: column;
        width: 300px;
    }
}
